.wrapper {
  background-color: rgb(71, 38, 71);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* padding: 5px; */
}

main {
  justify-content: center;
  align-items: center;
  border: 10px ridge purple;
  background-color: purple;
  color: white;
  height: 90%;
  width: 50vw;  
  padding: 10px;
}

.button {
  padding: 10px;
  font-size: larger;
}

#result {
  align-self: center;
  background-color: plum;
  padding: 10px;
  border: 5px ridge purple;
  font-size: larger;
  font-weight: bold;
  font-size: larger;
  width: fit-content;
  margin: 0 auto;
  color: black;

}

.vamp img {
  margin: 0 auto 20px auto;
  display: block;
  max-height: 150px;
  max-width: 90%;
  object-fit: cover;
  border: 5px ridge rgb(255, 255, 255);
  box-shadow: 10px 10px 10px black;
}

audio::-webkit-media-controls-panel {
  background-color: lavender;
}

.announcement {
  display: flex;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;
  gap: 10px;
}

#synth {
  width: fit-content;
  margin: 0 auto;
  display: flex;
  gap: 5px;
}

.recording-section {
  width: 18%;
  height: 90%;
  padding: 10px;
  border: 10px ridge lightblue;
  text-align: center;
  background-color: lightblue;
}

.recordings {
  overflow-y: scroll;
  max-height: 80%;
  align-items: center;
  width: 100%;
  margin-top: 50px;
}

.recording {
  width: 90%;
  margin-bottom: 30px;
  background-color: rgba(0, 0, 0, 0);
  filter: drop-shadow(5px 5px 3px black);
}

.left-panel {
  border: 10px ridge lightblue;
  background-color: lightblue;
  padding: 10px;
  width: 18%;
  height: 90vh;
  position: relative;
}

.left-panel-content {
  overflow-y: scroll;
  height: 100%;
}

.left-panel:before {
  content:'';
  width:100%;
  height:100%;    
  position:absolute;
  left:0;
  top:0;
  background:linear-gradient(transparent 88vh, lightblue);
  pointer-events: none;
}


li {
  margin-bottom: 10px;
}

.left-panel h3 {
  margin-bottom: 0;
}
.left-panel ul, .left-panel p {
  margin-top: 10px;
  margin-bottom: 30px;
}

.left-panel p:last-of-type {
  margin-bottom: 10px;
}

.left-panel h1 {
  margin-top: 10px;
  text-align: center;
}

.recording-section h1 {
  margin-top: 10px;
}

.feedback-link {
  text-decoration: underline;
  cursor: pointer;
  color: purple;
  font-weight: bold;
}