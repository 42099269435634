.modal {
    position: absolute;
    z-index: 2;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.5);
}

.modal.hidden {
    display: none;
}

.hidden {
    display: none;
}

.feedback-form {
    width: 50vw;
    height: 50vh;
    border: 10px ridge lightblue;
    text-align: center;
    background-color: lightblue;
    position: relative;
    min-width: fit-content;
    min-height: 380px
}

h1 {
    font-size: xx-large;
}

.field {
    text-align: left;
    margin-bottom: 10px;
}

form {
    width: fit-content;
    margin: 0 auto;

}

input {
    margin: 5px 0px;
}

.close {
    position: absolute;
    right: 10px;
    background-color: none;
    background: none;
    cursor: pointer;
    border: none;
    font-size: 25pt;
}

#message {
    height: 100px;
    width: 300px;
}

.success {
    text-align: center;
}