
.record {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 1px solid rgba(0,0,0,.5);
    margin: 5px;
    background-color: red;
    cursor: pointer;
  }

  @keyframes recording {
      from {background-color: rgba(255,0,0,1)}
      to {background-color: rgba(255,0,0,0)}
  }

  .record.active {
    background-color: white;
  }

  .record-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 20px;
  }

  .record-button {
      background-color: grey;
      border-radius: 2px;
  }

  .record-button.active{
    background-color: red;
    border-radius: 2px;
}